import smoothscroll from 'smoothscroll-polyfill';
import Splide from '@splidejs/splide';

smoothscroll.polyfill();

class App {
    constructor() {
        this.datas = [];
        this.slide_time = 3000;
        this.transition_time = 1000;
    }
    page_scroll(mySelecor) {
        console.log(mySelecor);
        const targetElement = document.querySelector(mySelecor);
        if (!targetElement) {
            return;
        }

        // 画面上部から要素までの距離
        const rectTop = targetElement.getBoundingClientRect().top;
        // 現在のスクロール距離
        const offsetTop = window.pageYOffset;
        // スクロール位置に持たせるバッファ
        const buffer = 0;
        const top = rectTop + offsetTop - buffer;

        window.scrollTo({
            top,
            behavior: 'smooth',
        });
    }

    gnav() {
        const trigger = document.querySelector('.menu-trigger');
        const header_nav = document.querySelector('.header-nav');
        trigger.addEventListener('click', (e) => {
            e.preventDefault();
            trigger.classList.toggle('active');
            header_nav.classList.toggle('active');
        }, false);
    }

    works_slide() {
        const _self = this;
        let splides = document.getElementById('splide');
        if (splides) {
            let secondaryslider = new Splide('#secondary-slider', {
                fixedWidth: 140,
                rewind: true,
                isNavigation: true,
                // type: 'loop',
                gap: 10,
                // cover: true,
                pagination: false,
            }).mount();

            let primaryslider = new Splide('#splide', {
                type: 'fade',
                // heightRatio: 0.5,
                pagination: false,
                arrows: false,
                // cover: true,
            });

            primaryslider.sync(secondaryslider).mount();
        }
    }

    input_file() {
        const formFiles = document.querySelectorAll('.input-file');
        if (formFiles.length > 0) {
            formFiles.forEach((elm) => {
                elm.onchange = function () {
                    const label = this.nextElementSibling;
                    let spans = label.querySelector('span');
                    if (spans) {
                        spans.remove();
                    }
                    const file = this.files[0].name;
                    const span = document.createElement('span');
                    span.innerText = file;
                    label.appendChild(span);
                };
            });
        }
    }

    scrollObserver() {
        // オプション指定
        const options = {
            // 要素の見えている割合が20%を超える度にコールバックを実行
            threshold: [0, 0.2, 0.4, 0.6, 0.8, 1],
        };

        // 交差した時に発生するコールバック
        const callback = (entries, observer) => {
            entries.forEach((entry) => {
                // 交差している領域の割合が20%を超えた場合
                if (entry.intersectionRatio > 0.2) {
                    // アニメーションや画像の読み込みなどの処理
                    entry.target.classList.add('on');
                }
            });
        };

        // IntersectionObserverをインスタンス化
        const observer = new IntersectionObserver(callback, options);

        // 監視する要素の配列を取得
        const observers = [...document.querySelectorAll('.js-scroll')];

        // 配列に指定した要素をIntersectionObserverで監視
        observers.forEach((el) => {
            observer.observe(el);
        });
    }

    init() {
        const _self = this;
        let tabli = document.querySelectorAll('.works-tab li');
        if (tabli.length > 0) {
            tabli.forEach((li) => {
                li.addEventListener('click', function () {
                    let tab = this.getAttribute('data-tab');
                    _self.page_scroll('#' + tab);
                });
            });
        }

        _self.works_slide();
        _self.input_file();
        // _self.gnav();
    }
}

let app = new App();

document.addEventListener('DOMContentLoaded', () => {
    app.init();
});

window.addEventListener('load', () => {

});
